import { KeyValueDbDao } from "../../../../gyzmo-commons/dao/db/keyValue.db.dao";
import { InspectionWsDaoV2 } from "../../../dao/ws/inspection/v2/inspection.ws.dao";
import { ServersConnectionsProvider } from "../../../providers/serversConnections.provider";

export abstract class InspectionServiceBase {
    protected constructor(protected keyValueDbDao: KeyValueDbDao,
                          protected inspectionWsDao: InspectionWsDaoV2,
                          protected serversConnectionsProvider: ServersConnectionsProvider) {
    }

    public initializeOnWorkOrder(workOrderId: string): Promise<string> {
        return this.inspectionWsDao.initializeOnWorkOrder(this.serversConnectionsProvider.getServerConnection(), workOrderId);
    }

    public initializeByEquipmentId(equipmentId: string): Promise<string> {
        return this.inspectionWsDao.initializeByEquipmentId(this.serversConnectionsProvider.getServerConnection(), equipmentId);
    }

    public initializeDepartureByMovementId(movementId: string): Promise<string> {
        return this.inspectionWsDao.initializeDepartureByMovementId(this.serversConnectionsProvider.getServerConnection(), movementId);
    }

    public initializeReturnByMovementId(movementId: string): Promise<string> {
        return this.inspectionWsDao.initializeReturnByMovementId(this.serversConnectionsProvider.getServerConnection(), movementId);
    }
}
