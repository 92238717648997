import { Injectable } from "@angular/core";
import { DateTime } from "luxon/src/datetime";
import { DbDaoBase } from "../../../../../gyzmo-commons/dao/db/base/db.dao.base";
import { IdWordingDbDao } from "../../../../../gyzmo-commons/dao/db/idWording.db.dao";
import { isNullOrEmpty } from "../../../../../gyzmo-commons/helpers/null.helper";
import { DATE_NODEJS_FORMAT } from "../../../../../gyzmo-commons/interfaces/constants";
import { AppSqlProvider } from "../../../../../gyzmo-commons/persistence/app.sql.provider";
import { LoggerService } from "../../../../../gyzmo-commons/services/logs/logger.service";
import { Inspection } from "../../../../models/inspection/v2/inspection.model";
import { Movement } from "../../../../models/movement.model";
import { AttachmentDbDao } from "../../attachment.db.dao";
import { EquipmentDbDao } from "../../equipment.db.dao";
import { MovementDbDao } from "../../movement.db.dao";
import { ChecklistDbDaoV2 } from "./checklist.db.dao";
import { FaceDbDaoV2 } from "./face.db.dao";

@Injectable({
    providedIn: "root",
})
export class InspectionDbDaoV2 extends DbDaoBase<Inspection> {
    constructor(logger: LoggerService,
                private sqlProvider: AppSqlProvider,
                private equipmentDbDao: EquipmentDbDao,
                private movementDbDao: MovementDbDao,
                private checklistDbDao: ChecklistDbDaoV2,
                private idWordingDbDao: IdWordingDbDao,
                private faceDbDao: FaceDbDaoV2,
                private attachmentDbDao: AttachmentDbDao) {
        super(logger);
    }

    public getList(startDate: DateTime, endDate: DateTime,
                   hydrateEquipment: boolean = false,
                   hydrateMovement: boolean = false,
                   hydrateObjects: boolean = false,
                   hydrateWears: boolean = false): Promise<Inspection[]> {
        const selectQuery = "SELECT " + Inspection.TABLENAME + ".*, startDate, returnDate FROM " + Inspection.TABLENAME
                            + " LEFT JOIN " + Movement.TABLENAME + " ON " + Movement.TABLENAME + ".id = " + Inspection.TABLENAME + ".movement"
                            + " WHERE"
                            + " (visitDate >= '" + startDate.toFormat(DATE_NODEJS_FORMAT) + "' AND visitDate <= '" + endDate.toFormat(DATE_NODEJS_FORMAT) + "')"
                            + " ORDER BY visitDate;";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return [];
                }

                let inspections: Inspection[] = [];
                for (const item of data.rows) {
                    inspections.push(this.rowToModel(item));
                }

                let hydratationPromises = [];
                inspections.forEach(inspection => {
                    hydratationPromises.push(this.idWordingDbDao.get(inspection.inspectionModel.id, true)
                        .then(value => {
                            inspection.inspectionModel = value;
                        }));

                    if (hydrateEquipment) {
                        hydratationPromises.push(this.equipmentDbDao.get(inspection.equipment.id, hydrateEquipment)
                            .then(value => {
                                inspection.equipment = value;
                            }));
                    }

                    if (hydrateMovement) {
                        hydratationPromises.push(this.movementDbDao.get(inspection.movement.id, hydrateMovement)
                            .then(value => {
                                inspection.movement = value;
                            }));
                    }

                    if (hydrateObjects) {
                        let checklistsIds = inspection.checklistsIds.split(";");
                        hydratationPromises.push(this.checklistDbDao.getList(checklistsIds, inspection.id, hydrateObjects)
                            .then(value => {
                                inspection.checklists = value;
                            }));

                        let facesIds = inspection.facesIds.split(";");
                        hydratationPromises.push(this.faceDbDao.getList(facesIds, inspection.id, hydrateObjects)
                            .then(value => {
                                inspection.faces = value;
                            }));

                        hydratationPromises.push(this.attachmentDbDao.getByObjectAndKey("inspection", inspection.id)
                            .then(value => {
                                inspection.attachments = value;
                            }));
                    }
                });

                return Promise.all(hydratationPromises)
                    .then(() => {
                        return inspections;
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public async createIndexes(): Promise<void> {
        let query = "CREATE INDEX IF NOT EXISTS idx_" + Inspection.TABLENAME + "_id"
                    + " ON " + Inspection.TABLENAME + "(id);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + Inspection.TABLENAME + "_visitDate"
                + " ON " + Inspection.TABLENAME + "(visitDate);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });

        query = "CREATE INDEX IF NOT EXISTS idx_" + Inspection.TABLENAME + "_movement"
                + " ON " + Inspection.TABLENAME + "(movement);";

        await this.sqlProvider.query(query)
            .catch(reason => {
                this.logSqlError(reason);
            });
    }

    public createTable(): Promise<void> {
        let query = "CREATE TABLE IF NOT EXISTS " + Inspection.TABLENAME
                    + " ("
                    + "id TEXT PRIMARY KEY,"
                    + "updateDate DATE,"
                    + "visitDate DATE,"
                    + "agentName TEXT,"
                    + "driverName TEXT,"
                    + "driverMail TEXT,"
                    + "linkedObject TEXT,"
                    + "inspectionModel TEXT,"
                    + "user TEXT,"
                    + "equipment TEXT,"
                    + "movement TEXT,"
                    + "kind TEXT,"
                    + "mileage NUMERIC,"
                    + "mileage2 NUMERIC,"
                    + "checklistsIds TEXT,"
                    + "facesIds TEXT"
                    + ");";

        return this.sqlProvider.query(query)
            .then(async () => {
                await this.createIndexes();
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public delete(id: string): Promise<any> {
        let promises = [];
        promises.push(this.checklistDbDao.delete(id));
        promises.push(this.attachmentDbDao.delete(id));

        return Promise.all(promises)
            .then(value => {
                let selectQuery = "DELETE FROM " + Inspection.TABLENAME + " WHERE id = '" + id + "';";
                return this.sqlProvider.query(selectQuery);
            });
    }

    deleteAll(): Promise<any> {
        let selectQuery = "DELETE FROM " + Inspection.TABLENAME + ";";
        return this.sqlProvider.query(selectQuery);
    }

    public get(id: string, hydrate: boolean = false): Promise<Inspection> {
        if (isNullOrEmpty(id)) {
            return Promise.resolve(null);
        }
        let selectQuery = "SELECT * FROM " + Inspection.TABLENAME + " WHERE id = '" + id + "';";

        return this.sqlProvider.query(selectQuery)
            .then(data => {
                if (data.rows.length <= 0) {
                    return null;
                }

                let inspection: Inspection = this.rowToModel(data.rows[0]);

                let hydratationPromises = [];

                if (hydrate) {
                    hydratationPromises.push(this.idWordingDbDao.get(inspection.inspectionModel.id, true)
                        .then(value => {
                            inspection.inspectionModel = value;
                        }));

                    hydratationPromises.push(this.equipmentDbDao.get(inspection.equipment.id, hydrate)
                        .then(value => {
                            inspection.equipment = value;
                        }));

                    hydratationPromises.push(this.movementDbDao.get(inspection.movement.id, hydrate)
                        .then(value => {
                            inspection.movement = value;
                        }));

                    let checklistsIds = inspection.checklistsIds.split(";");
                    hydratationPromises.push(this.checklistDbDao.getList(checklistsIds, inspection.id, hydrate)
                        .then(value => {
                            inspection.checklists = value;
                        }));

                    let facesIds = inspection.facesIds.split(";");
                    hydratationPromises.push(this.faceDbDao.getList(facesIds, inspection.id, hydrate)
                        .then(value => {
                            inspection.faces = value;
                        }));

                    hydratationPromises.push(this.attachmentDbDao.getByObjectAndKey("inspection", inspection.id)
                        .then(value => {
                            inspection.attachments = value;
                        }));
                }

                return Promise.all(hydratationPromises)
                    .then(() => {
                        return inspection;
                    });
            })
            .catch(reason => {
                this.logSqlError(reason);
                return null;
            });
    }

    public getTableName(): string {
        return Inspection.TABLENAME;
    }

    protected rowToModel(row: any): Inspection {
        let inspection = new Inspection();

        inspection.id = row.id;
        inspection.updateDate = row.updateDate;
        inspection.visitDate = row.visitDate;
        inspection.agentName = row.agentName;
        inspection.driverName = row.driverName;
        inspection.driverMail = row.driverMail;
        inspection.linkedObject = row.linkedObject;
        inspection.kind = row.kind;
        inspection.mileage = Number(row.mileage);
        inspection.mileage2 = Number(row.mileage2);
        inspection.checklistsIds = row.checklistsIds;
        inspection.facesIds = row.facesIds;

        inspection.equipment.id = row.equipment;
        inspection.movement.id = row.movement;
        inspection.driverMail = row.driverMail;

        inspection.inspectionModel.id = row.inspectionModel;
        inspection.user = JSON.parse(row.user);

        return inspection;
    }

    public save(inspection: Inspection): Promise<Inspection> {
        let promises = [];
        promises.push(this.equipmentDbDao.save(inspection.equipment));

        if (inspection.inspectionModel) {
            promises.push(this.idWordingDbDao.save(inspection.inspectionModel));
        }

        if (inspection.movement) {
            promises.push(this.movementDbDao.save(inspection.movement));
        }

        inspection.checklists.forEach(checklist => {
            promises.push(this.checklistDbDao.save(checklist));
        });

        inspection.faces.forEach(face => {
            promises.push(this.faceDbDao.save(face));
        });

        inspection.attachments.forEach(attachment => {
            promises.push(this.attachmentDbDao.save(attachment));
        });

        return Promise.all(promises)
            .then(value => {
                let query = "INSERT OR REPLACE INTO " + Inspection.TABLENAME + " ("
                            + "id, updateDate, visitDate, agentName, driverName, driverMail, "
                            + "linkedObject, inspectionModel, user, equipment, movement, "
                            + "kind, mileage, mileage2, checklistsIds, facesIds"
                            + ") VALUES ("
                            + this.getValue(inspection.id)
                            + this.getValue(inspection.updateDate)
                            + this.getValue(inspection.visitDate)
                            + this.getValue(inspection.agentName)
                            + this.getValue(inspection.driverName)
                            + this.getValue(inspection.driverMail)
                            + this.getValue(inspection.linkedObject)
                            + this.getFkValue(inspection.inspectionModel)
                            + this.getValueAsJsonString(inspection.user)
                            + this.getFkValue(inspection.equipment)
                            + this.getFkValue(inspection.movement)
                            + this.getValue(inspection.kind)
                            + this.getValue(inspection.mileage)
                            + this.getValue(inspection.mileage2)
                            + this.getValue(inspection.checklistsIds)
                            + this.getValue(inspection.facesIds, true)
                            + ");";

                return this.sqlProvider.query(query)
                    .then(response => {
                        return inspection;
                    })
                    .catch(reason => {
                        this.logSqlError(reason);
                        return null;
                    });
            });
    }
}
