import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
    {
        path: "about", loadChildren: () => import("../gyzmo-commons/pages/about/about.module").then(m => m.AboutPageModule),
    },
    {
        path: "home", loadChildren: () => import("./home/home.module").then(m => m.HomeModule),
    },
    {
        path: "cgu", loadChildren: () => import("./account/cgu/cgu.module").then(m => m.CguPageModule),
    },
    {
        path: "privacy-policy", loadChildren: () => import("./account/privacy-policy/privacy-policy.module").then(m => m.PrivacyPolicyPageModule),
    },
    {
        path: "connection", loadChildren: () => import("./account/connection/connection.module").then(m => m.ConnectionPageModule),
    },
    {
        path: "equipment", loadChildren: () => import("./inspection/common/equipment/equipment.module").then(m => m.EquipmentPageModule),
    },
    {
        path: "checklist-v2", loadChildren: () => import("./inspection/V2/checklist/checklist.module").then(m => m.ChecklistPageModule),
    },
    {
        path: "face-v2", loadChildren: () => import("./inspection/V2/face/face.module").then(m => m.FacePageModule),
    },
    {
        path: "face-v2/:iterator", loadChildren: () => import("./inspection/V2/face/face.module").then(m => m.FacePageModule),
    },
    {
        path: "face-general-photo-v2", loadChildren: () => import("./inspection/V2/face-general-photo/face-general-photo.module").then(m => m.FaceGeneralPhotoPageModule),
    },
    {
        path: "inspection-summary-v2", loadChildren: () => import("./inspection/V2/inspection-summary/inspection-summary.module").then(m => m.InspectionSummaryPageModule),
    },
    {
        path: "work-order-search", loadChildren: () => import("./work-order-search/work-order-search.module").then(m => m.WorkOrderSearchModule),
    },
    {
        path: "work-order-details", loadChildren: () => import("./work-order-details/work-order-details.module").then(m => m.WorkOrderDetailsModule),
    },
    {
        path: "equipment-tyres-wear",
        loadChildren: () => import("./inspection/V2/equipment-tyres-wear/equipment-tyres-wear.module").then(m => m.EquipmentTyresWearPageModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            onSameUrlNavigation: "reload",
            initialNavigation: "disabled",
            scrollPositionRestoration: "enabled",
            useHash: true,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
