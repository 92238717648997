import { isNullOrEmpty } from "../../gyzmo-commons/helpers/null.helper";

export class CustomisationVisibilityMandatoryDto {
    visibility: boolean = true;
    mandatory: boolean = true;

    public static fromBody(body: any): CustomisationVisibilityMandatoryDto {
        let dto = new CustomisationVisibilityMandatoryDto();

        if (body.visibility != null) {
            dto.visibility = body.visibility;
        }
        if (body.mandatory != null) {
            dto.mandatory = body.mandatory;
        }

        return dto;
    }
}

export class CustomisationVisibilityMandatoryReadOnlyDto {
    visibility: boolean = true;
    mandatory: boolean = true;
    readOnly: boolean = false;

    public static fromBody(body: any): CustomisationVisibilityMandatoryReadOnlyDto {
        let dto = new CustomisationVisibilityMandatoryReadOnlyDto();

        if (body.visibility != null) {
            dto.visibility = body.visibility;
        }
        if (body.mandatory != null) {
            dto.mandatory = body.mandatory;
        }
        if (body.readOnly != null) {
            dto.readOnly = body.readOnly;
        }

        return dto;
    }
}

export class CustomisationMandatoryReadOnlyDto {
    mandatory: boolean = true;
    readOnly: boolean = false;

    public static fromBody(body: any): CustomisationMandatoryReadOnlyDto {
        let dto = new CustomisationMandatoryReadOnlyDto();

        if (body.mandatory != null) {
            dto.mandatory = body.mandatory;
        }
        if (body.readOnly != null) {
            dto.readOnly = body.readOnly;
        }

        return dto;
    }
}

export class CustomisationVisibilityMandatoryReadOnlyDefaultValueDto<T> {
    visibility: boolean = true;
    mandatory: boolean = true;
    readOnly: boolean = false;
    defaultValue: T;

    constructor(defaultValue: T) {
        this.defaultValue = defaultValue;
    }

    public static fromBody<T>(body: any, defaultValue: T): CustomisationVisibilityMandatoryReadOnlyDefaultValueDto<T> {
        let dto = new CustomisationVisibilityMandatoryReadOnlyDefaultValueDto(defaultValue);

        if (body.visibility != null) {
            dto.visibility = body.visibility;
        }
        if (body.mandatory != null) {
            dto.mandatory = body.mandatory;
        }
        if (body.readOnly != null) {
            dto.readOnly = body.readOnly;
        }
        if (body.defaultValue) {
            dto.defaultValue = body.defaultValue;
        }

        return dto;
    }
}

export class CustomisationVisibilityMandatoryMaxValuePerDayDto {
    visibility: boolean = true;
    mandatory: boolean = true;
    maxValuePerDay: number = 0;

    constructor(maxValuePerDay: number) {
        this.maxValuePerDay = maxValuePerDay;
    }

    public static fromBody(body: any): CustomisationVisibilityMandatoryMaxValuePerDayDto {
        let dto = new CustomisationVisibilityMandatoryMaxValuePerDayDto(0);

        if (body.visibility != null) {
            dto.visibility = body.visibility;
        }
        if (body.mandatory != null) {
            dto.mandatory = body.mandatory;
        }
        if (body.maxValuePerDay) {
            dto.maxValuePerDay = body.maxValuePerDay;
        }

        return dto;
    }
}

export class CustomisationHomeDto {
    infiniteViewEnabled: boolean = true;
    cardContent: string = "$EQUIPMENT_WORDING;$REGISTRATION_NUMBER;$MOVEMENT_KIND;$MOVEMENT_KEY;$DRIVER_NAME;$INSPECTION_HOUR;";
    generateDepartureEnabled: boolean = true;
    generateReturnEnabled: boolean = true;
    generateIntermediateEnabled: boolean = true;

    public static fromBody(body: any): CustomisationHomeDto {
        let dto = new CustomisationHomeDto();

        if (body.infiniteViewEnabled != null) {
            dto.infiniteViewEnabled = body.infiniteViewEnabled;
        }
        if (!isNullOrEmpty(body.cardContent)) {
            dto.cardContent = body.cardContent;
        }
        if (body.generateDepartureEnabled != null) {
            dto.generateDepartureEnabled = body.generateDepartureEnabled;
        }
        if (body.generateReturnEnabled != null) {
            dto.generateReturnEnabled = body.generateReturnEnabled;
        }
        if (body.generateIntermediateEnabled != null) {
            dto.generateIntermediateEnabled = body.generateIntermediateEnabled;
        }

        return dto;
    }
}

export class CustomisationSearchDto {
    searchByBarCodeEnabled: boolean = true;
    searchByLicensePlateEnabled: boolean = true;
    cardContent: string = "$WORK_ORDER_START_DATE;$CUSTOMER_NAME;$EQUIPMENT_WORDING;$REGISTRATION_NUMBER;$BOOKING_CODE;$WORK_ORDER_WORDING";

    public static fromBody(body: any): CustomisationSearchDto {
        let dto = new CustomisationSearchDto();

        if (body.searchByBarCodeEnabled != null) {
            dto.searchByBarCodeEnabled = body.searchByBarCodeEnabled;
        }
        if (body.searchByLicensePlateEnabled != null) {
            dto.searchByLicensePlateEnabled = body.searchByLicensePlateEnabled;
        }
        if (!isNullOrEmpty(body.cardContent)) {
            dto.cardContent = body.cardContent;
        }

        return dto;
    }
}

export class CustomisationMovementDto {
    equipmentInfoVisibility: boolean = true;
    customerInfoVisibility: boolean = true;
    driverInfoVisibility: boolean = true;
    canDeleteInspection: boolean = true;
    blockOnOutdatedControls: boolean = true;
    showMaintenanceControls: boolean = true;
    equipmentDatesVisibility: boolean = true;

    licensePicture: CustomisationVisibilityMandatoryDto;
    idPicture: CustomisationVisibilityMandatoryDto;
    counterPicture: CustomisationVisibilityMandatoryDto;
    startDate: CustomisationVisibilityMandatoryReadOnlyDefaultValueDto<any>;
    returnDate: CustomisationVisibilityMandatoryReadOnlyDefaultValueDto<any>;
    counter1: CustomisationVisibilityMandatoryMaxValuePerDayDto;
    counter2: CustomisationVisibilityMandatoryMaxValuePerDayDto;
    gauge1: CustomisationVisibilityMandatoryDto;
    gauge2: CustomisationVisibilityMandatoryDto;

    constructor() {
        this.licensePicture = new CustomisationVisibilityMandatoryDto();
        this.idPicture = new CustomisationVisibilityMandatoryDto();
        this.counterPicture = new CustomisationVisibilityMandatoryDto();
        this.startDate = new CustomisationVisibilityMandatoryReadOnlyDefaultValueDto<any>(null);
        this.returnDate = new CustomisationVisibilityMandatoryReadOnlyDefaultValueDto<any>(null);
        this.counter1 = new CustomisationVisibilityMandatoryMaxValuePerDayDto(9999);
        this.counter2 = new CustomisationVisibilityMandatoryMaxValuePerDayDto(9999);
        this.gauge1 = new CustomisationVisibilityMandatoryDto();
        this.gauge2 = new CustomisationVisibilityMandatoryDto();
    }

    public static fromBody(body: any): CustomisationMovementDto {
        let dto = new CustomisationMovementDto();

        if (body.equipmentInfoVisibility != null) {
            dto.equipmentInfoVisibility = body.equipmentInfoVisibility;
        }
        if (body.customerInfoVisibility != null) {
            dto.customerInfoVisibility = body.customerInfoVisibility;
        }
        if (body.driverInfoVisibility != null) {
            dto.driverInfoVisibility = body.driverInfoVisibility;
        }
        if (body.canDeleteInspection != null) {
            dto.canDeleteInspection = body.canDeleteInspection;
        }
        if (body.blockOnOutdatedControls != null) {
            dto.blockOnOutdatedControls = body.blockOnOutdatedControls;
        }
        if (body.showMaintenanceControls != null) {
            dto.showMaintenanceControls = body.showMaintenanceControls;
        }
        if (body.equipmentDatesVisibility != null) {
            dto.equipmentDatesVisibility = body.equipmentDatesVisibility;
        }

        if (body.licensePicture) {
            dto.licensePicture = CustomisationVisibilityMandatoryDto.fromBody(body.licensePicture);
        }
        if (body.idPicture) {
            dto.idPicture = CustomisationVisibilityMandatoryDto.fromBody(body.idPicture);
        }
        if (body.counterPicture) {
            dto.counterPicture = CustomisationVisibilityMandatoryDto.fromBody(body.counterPicture);
        }
        if (body.startDate) {
            dto.startDate = CustomisationVisibilityMandatoryReadOnlyDefaultValueDto.fromBody<any>(body.startDate, null);
        }
        if (body.returnDate) {
            dto.returnDate = CustomisationVisibilityMandatoryReadOnlyDefaultValueDto.fromBody<any>(body.returnDate, null);
        }
        if (body.counter1) {
            dto.counter1 = CustomisationVisibilityMandatoryMaxValuePerDayDto.fromBody(body.counter1);
        }
        if (body.counter2) {
            dto.counter2 = CustomisationVisibilityMandatoryMaxValuePerDayDto.fromBody(body.counter2);
        }
        if (body.gauge1) {
            dto.gauge1 = CustomisationVisibilityMandatoryDto.fromBody(body.gauge1);
        }
        if (body.gauge2) {
            dto.gauge2 = CustomisationVisibilityMandatoryDto.fromBody(body.gauge2);
        }

        return dto;
    }
}

export class CustomisationChecklistDto {
    generalComment: CustomisationVisibilityMandatoryDto;
    pictureTakingEnabled: boolean = true;

    constructor() {
        this.generalComment = new CustomisationVisibilityMandatoryDto();
        this.generalComment.mandatory = false;
    }

    public static fromBody(body: any): CustomisationChecklistDto {
        let dto = new CustomisationChecklistDto();

        if (body.generalComment) {
            dto.generalComment = CustomisationVisibilityMandatoryDto.fromBody(body.generalComment);
        }
        if (body.pictureTakingEnabled != null) {
            dto.pictureTakingEnabled = body.pictureTakingEnabled;
        }

        return dto;
    }
}

export class CustomisationFacesDto {
    damagesVisibility: boolean = true;
    zoningVisibility: boolean = true;

    public static fromBody(body: any): CustomisationFacesDto {
        let dto = new CustomisationFacesDto();

        if (body.damagesVisibility != null) {
            dto.damagesVisibility = body.damagesVisibility;
        }
        if (body.zoningVisibility != null) {
            dto.zoningVisibility = body.zoningVisibility;
        }

        return dto;
    }
}

export class CustomisationI18NLabelsDto {
    id: string = "";
    wording: string = "";
    labels: [] = [];

    public static fromBody(body: any): CustomisationI18NLabelsDto {
        let dto = new CustomisationI18NLabelsDto();

        if (body.id) {
            dto.id = "" + body.id;
        }
        if (body.wording) {
            dto.wording = "" + body.wording;
        }
        if (body.labels) {
            dto.labels = body.labels;
        }

        return dto;
    }
}

export class CustomisationSignatureDenialDto {
    visibility: boolean = true;
    reasons: CustomisationI18NLabelsDto[] = [];

    public static fromBody(body: any): CustomisationSignatureDenialDto {
        let dto = new CustomisationSignatureDenialDto();

        if (body.visibility != null) {
            dto.visibility = body.visibility;
        }

        if (body.reasons) {
            dto.reasons = [];
            (body.reasons as []).forEach(value => {
                dto.reasons.push(CustomisationI18NLabelsDto.fromBody(value));
            });
        }

        return dto;
    }
}

export class CustomisationSummaryDto {
    contractInfoVisibility: boolean = true;
    equipmentVisibility: boolean = true;
    customerVisibility: boolean = true;
    driverVisibility: boolean = true;
    agentName: CustomisationVisibilityMandatoryReadOnlyDto;
    customerName: CustomisationVisibilityMandatoryReadOnlyDto;
    termsAndConditions: CustomisationVisibilityMandatoryReadOnlyDefaultValueDto<boolean>;
    driverLicenseValidity: CustomisationVisibilityMandatoryReadOnlyDefaultValueDto<boolean>;
    sendInspectionByMail: CustomisationVisibilityMandatoryReadOnlyDefaultValueDto<boolean>;
    signatureDenial: CustomisationSignatureDenialDto;

    constructor() {
        this.agentName = new CustomisationVisibilityMandatoryReadOnlyDto();
        this.customerName = new CustomisationVisibilityMandatoryReadOnlyDto();
        this.termsAndConditions = new CustomisationVisibilityMandatoryReadOnlyDefaultValueDto<boolean>(false);
        this.driverLicenseValidity = new CustomisationVisibilityMandatoryReadOnlyDefaultValueDto<boolean>(false);
        this.sendInspectionByMail = new CustomisationVisibilityMandatoryReadOnlyDefaultValueDto<boolean>(true);
        this.signatureDenial = new CustomisationSignatureDenialDto();
    }

    public static fromBody(body: any): CustomisationSummaryDto {
        let dto = new CustomisationSummaryDto();

        if (body.contractInfoVisibility != null) {
            dto.contractInfoVisibility = body.contractInfoVisibility;
        }
        if (body.equipmentVisibility != null) {
            dto.equipmentVisibility = body.equipmentVisibility;
        }
        if (body.customerVisibility != null) {
            dto.customerVisibility = body.customerVisibility;
        }
        if (body.driverVisibility != null) {
            dto.driverVisibility = body.driverVisibility;
        }
        if (body.agentName) {
            dto.agentName = CustomisationVisibilityMandatoryReadOnlyDto.fromBody(body.agentName);
        }
        if (body.customerName) {
            dto.customerName = CustomisationVisibilityMandatoryReadOnlyDto.fromBody(body.customerName);
        }
        if (body.termsAndConditions) {
            dto.termsAndConditions = CustomisationVisibilityMandatoryReadOnlyDefaultValueDto.fromBody<boolean>(body.termsAndConditions, false);
        }
        if (body.driverLicenseValidity) {
            dto.driverLicenseValidity = CustomisationVisibilityMandatoryReadOnlyDefaultValueDto.fromBody<boolean>(body.driverLicenseValidity, false);
        }
        if (body.sendInspectionByMail) {
            dto.sendInspectionByMail = CustomisationVisibilityMandatoryReadOnlyDefaultValueDto.fromBody<boolean>(body.sendInspectionByMail, false);
        }
        if (body.signatureDenial) {
            dto.signatureDenial = CustomisationSignatureDenialDto.fromBody(body.signatureDenial);
        }

        return dto;
    }
}

export class CustomisationDto {
    home: CustomisationHomeDto;
    search: CustomisationSearchDto;
    movement: CustomisationMovementDto;
    checklist: CustomisationChecklistDto;
    faces: CustomisationFacesDto;
    generalPictures: CustomisationI18NLabelsDto[];
    summary: CustomisationSummaryDto;

    constructor() {
        this.home = new CustomisationHomeDto();
        this.search = new CustomisationSearchDto();
        this.movement = new CustomisationMovementDto();
        this.checklist = new CustomisationChecklistDto();
        this.faces = new CustomisationFacesDto();
        this.generalPictures = [];
        this.generalPictures.push(CustomisationI18NLabelsDto.fromBody({
            id: "3_4_ARRIERE",
            details: "/3_4_ARRIERE",
            wording: "3/4 Arrière",
            isActive: true,
            order: "PG1",
            filterA: "H24, VU",
        }));
        this.generalPictures.push(CustomisationI18NLabelsDto.fromBody({
            id: "3_4_AVANT",
            details: "/3_4_AVANT",
            wording: "3/4 Avant",
            isActive: true,
            order: "PG2",
            filterA: "H24,VU",
        }));

        this.summary = new CustomisationSummaryDto();
    }

    public static fromBody(body: any): CustomisationDto {
        let dto = new CustomisationDto();

        if (body.home) {
            dto.home = CustomisationHomeDto.fromBody(body.home);
        }

        if (body.search) {
            dto.search = CustomisationSearchDto.fromBody(body.search);
        }

        if (body.movement) {
            dto.movement = CustomisationMovementDto.fromBody(body.movement);
        }

        if (body.checklist) {
            dto.checklist = CustomisationChecklistDto.fromBody(body.checklist);
        }

        if (body.faces) {
            dto.faces = CustomisationFacesDto.fromBody(body.faces);
        }

        if (body.generalPictures) {
            dto.generalPictures = [];
            (body.generalPictures as []).forEach(value => {
                dto.generalPictures.push(CustomisationI18NLabelsDto.fromBody(value));
            });
        }

        if (body.summary) {
            dto.summary = CustomisationSummaryDto.fromBody(body.summary);
        }

        return dto;
    }
}
